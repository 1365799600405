import type { GetServerSideProps, NextPage } from 'next';
import { z } from 'zod';
import {
  getApolloSsrClient,
  getVisitorId,
  ssrThemeProps,
} from '@stitch-fix/sf-next/server';
import Script from 'next/script';
import { getCachedEntryByUrl } from '../utils/requests/contentstack/cachedRequests';
import { formatError, serverLogger } from '../utils/loggers/server';
import { modularPageWrapperSchema } from '../components/ModularPageWrapper/schema';
import { ModularPageWrapper } from '../components/ModularPageWrapper';
import { isAllSettledResponseFailure } from '../utils/promises';
import { getDynamicAdsRequest } from '../features/dynamicAds/utils/getDynamicAdsRequest';
import { getModulesWithHeroOverride } from '../features/dynamicAds/utils/getModulesWithHeroOverride';
import { allocateVisitorSsr } from '../utils/allocateVisitorSsr/allocateVisitorSsr';
import { BLUESHIFT_MODAL_EXPERIMENT } from '../utils/helpers';

export type GatewayPageProps = z.infer<typeof modularPageWrapperSchema> & {
  displayBlueshiftModal?: boolean;
};

export const getServerSideProps: GetServerSideProps<GatewayPageProps> = async ({
  locale,
  req,
}) => {
  const visitorId = getVisitorId(req);
  const apolloSsrClient = getApolloSsrClient({ req });

  try {
    const dynamicAdsRequest = getDynamicAdsRequest({ req });
    const entryRequest = getCachedEntryByUrl({
      contentType: 'modular_page',
      url: '/gateway-onboarding-vision',
      language: locale,
      schema: modularPageWrapperSchema,
      logger: serverLogger,
    });

    // Blueshift Modal Experiment
    const blueshiftModalExperimentRequest = allocateVisitorSsr({
      req,
      visitorId,
      apolloSsrClient,
      configParams: [
        {
          expectedValues: ['control', 'treatment'],
          fallbackValue: 'control',
          name: BLUESHIFT_MODAL_EXPERIMENT,
        },
      ],
    });

    const responses = await Promise.allSettled([
      entryRequest,
      dynamicAdsRequest,
      blueshiftModalExperimentRequest,
    ]);

    const [entryResponse, dynamicAdsResponse, blueshiftModalResponse] =
      responses;
    const dynamicAdsData =
      dynamicAdsResponse.status === 'fulfilled'
        ? dynamicAdsResponse.value?.data
        : undefined;

    if (isAllSettledResponseFailure(entryResponse)) {
      throw entryResponse.reason;
    }

    const shouldShowBlueshiftModal =
      blueshiftModalResponse.status === 'fulfilled' &&
      blueshiftModalResponse.value?.data?.visitor.configParams.some(
        p => p.name === BLUESHIFT_MODAL_EXPERIMENT && p.value === 'treatment',
      );

    return {
      props: {
        seoData: entryResponse.value.data.seoData,
        modules: getModulesWithHeroOverride({
          businessLinePage: 'gateway',
          modules: entryResponse.value.data.modules,
          dynamicAds: dynamicAdsData,
        }),
        ...(await ssrThemeProps({
          req,
          overrideTheme: 'brand-2024-full',
        })),
        displayBlueshiftModal: shouldShowBlueshiftModal,
      },
    };
  } catch (error: unknown) {
    serverLogger({
      level: 'error',
      team: 'client-onboarding',
      message: 'Required page data is undefined - Gateway',
      context: {
        error: formatError(error),
        page: '/',
        severity: 'critical',
      },
    });

    throw new Error('Required data not provided to render Gateway page');
  }
};

const GatewayPage: NextPage<GatewayPageProps> = ({
  seoData,
  modules,
  displayBlueshiftModal,
}) => {
  return (
    <>
      {displayBlueshiftModal && (
        <Script
          // Blueshift web conversion integration (https://experiences.getblueshift.com/)
          src="https://www.lightboxcdn.com/vendor/3767d51a-4af9-4270-8ee4-c7310e4371b0/lightbox_speed.js"
          defer
        />
      )}
      <ModularPageWrapper seoData={seoData} modules={modules} />
    </>
  );
};

export default GatewayPage;
